<template>
  <div class="wrapper">
    <HeroRedirect :data="heroProps" />
  </div>
</template>

<script>
import HeroRedirect from "@/components/Heroes/HeroRedirect.vue";
import { mapGetters } from "vuex";
import { manageSEO } from "@/services/dataManagement.js";

export default {
  name: "RedirOk",
  metaInfo() {
    return manageSEO(this.seo, this.seo.general, false, this.dealerName);
  },
  components: {
    HeroRedirect,
  },
  data() {
    return {
      dealerName: this.$store.state.dealerInfo.dealerName,
      redirData: {
        heroTitle: "OBRIGADA PELO SEU CONTACTO",
        heroText: this.$store.state.isLexus
          ? "Agradecemos o seu interesse pela marca Lexus. Em breve, vamos entrar em contacto consigo."
          : "Agradecemos o seu interesse pela marca Toyota. Em breve, vamos entrar em contacto consigo.",
      },
    };
  },
  computed: {
    ...mapGetters({
      getProp: "form/getProp",
    }),
    heroProps() {
      const obj = this.getProp("formSuccess");
      return Object.keys(obj).length !== 0 ? obj : this.redirData;
    },
    seo() {
      return this.$store.state.homepage.seo;
    },
  },
};
</script>

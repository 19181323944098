<template>
  <Hero :image="data.image || image" classHero="hero-100 ">
    <template v-slot:heroImageText>
      <div class="hero-image-info-redirect redirect-page">
        <div class="hero-image-redirect-text">
          <p class="hero-image-redirect-title">{{ data.heroTitle }}</p>
          <p class="hero-image-redirect-subtitle">{{ data.heroText }}</p>
        </div>
      </div>
    </template>
  </Hero>
</template>

<script>
import Hero from "@/components/Heroes/Hero.vue";
import headerImage from "@/assets/form-header.jpg";

export default {
  name: "HeroHomepage",
  components: {
    Hero,
  },
  props: {
    data: { type: Object },
  },
  data() {
    return {
      image: {
        imageUrl: headerImage,
        imageAlt: "",
        imageTitle: "",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/Heroes";
</style>
